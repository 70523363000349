import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';

export default defineNuxtRouteMiddleware(() => {
  const { nawCompleted } = toRefs(useAuthStore());

  if (nawCompleted.value) {
    return navigateTo({ path: '/mijn-profiel' });
  }
});
